import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigService } from '../services/app-config.service';
import { TokenService } from '../services/token.service';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE';

const NO_INTERCEPT_URL_PATHS = new Map<string, HttpMethod[]>([
	['/api/v1/auth/login/', []],
	['/api/v1/auth/password/reset/', []],
	['/api/v1/auth/password/reset/confirm/', []],
	['/api/v1/news/news/', ['GET']],
]);

/**
 * Interceptor to add access token to requests.
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	private readonly appConfigService = inject(AppConfigService);

	private readonly tokenService = inject(TokenService);

	/**
	 * Appends bearer token.
	 * @inheritdoc
	 */
	public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		if (this.shouldInterceptToken(req)) {
			const token = this.tokenService.getToken();

			const newReq = req.clone({
				headers: req.headers.set('Authorization', `Token ${token}`),
			});

			return next.handle(newReq);
		}

		// Do nothing.
		return next.handle(req);
	}

	/**
	 * Checks if a request is for authorization or refresh token.
	 * @param req Request.
	 */
	private shouldInterceptToken(req: HttpRequest<unknown>): boolean {
		if (!req.url.startsWith(this.appConfigService.apiUrl)) {
			return false;
		}

		const { pathname } = new URL(req.url);

		if (![...NO_INTERCEPT_URL_PATHS.keys()].some(a => pathname.startsWith(a))) {
			return true;
		}

		const noInterceptMethods = [...NO_INTERCEPT_URL_PATHS.entries()]
			.filter(([path]) => pathname.startsWith(path))
			.reduce((acc, [, value]) => [...acc, ...value], [] as HttpMethod[]);

		const noTokenIntercept = noInterceptMethods?.length === 0 || noInterceptMethods?.includes(req.method as HttpMethod);

		return !noTokenIntercept;
	}
}
