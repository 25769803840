import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { catchHttpErrorResponse } from '../rxjs/catch-http-error-responce';
import { CurrentUserService } from '../services/current-user.service';

/**
 * Interceptor to add access token to requests.
 */
@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

	private readonly currentUserService = inject(CurrentUserService);

	/** Request to refresh token. */
	private refreshTokenRequest$: Observable<void> | null = null;

	/**
	 * Refreshes a token.
	 * @inheritdoc
	 */
	public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		return next.handle(req).pipe(
			catchHttpErrorResponse(error => {
				if (this.shouldHttpErrorBeIgnored(error)) {
					throw error;
				}

				this.refreshTokenRequest$ = this.refreshTokenRequest$ ?? this.currentUserService.refreshSecret();
				return this.refreshTokenRequest$.pipe(
					tap(() => {
						this.refreshTokenRequest$ = null;
					}),
					switchMap(() => next.handle(req)),
				);
			}),
		);
	}

	private shouldHttpErrorBeIgnored(error: HttpErrorResponse): boolean {
		return error.status !== HttpStatusCode.Unauthorized;
	}
}
