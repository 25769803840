import { Injectable, inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

/** Custom icon data. */
type IconData = {

	/** Icon name. */
	readonly name: string;

	/** Path to icon. */
	readonly path: string;
};

/**
 * Service to register custom icons.
 */
@Injectable({
	providedIn: 'root',
})
export class IconsService {
	private readonly matIconRegistry = inject(MatIconRegistry);

	private readonly domSanitizer = inject(DomSanitizer);

	private readonly icons: IconData[] = [
		{
			name: 'about-us',
			path: 'assets/icons/aboutus-accent.svg',
		},
		{
			name: 'account',
			path: 'assets/icons/account.svg',
		},
		{
			name: 'accounts',
			path: 'assets/icons/accounts-dark.svg',
		},
		{
			name: 'add-account',
			path: 'assets/icons/addaccount-dark.svg',
		},
		{
			name: 'dashboard',
			path: 'assets/icons/dashboard-dark.svg',
		},
		{
			name: 'edit',
			path: 'assets/icons/edit-dark.svg',
		},
		{
			name: 'export',
			path: 'assets/icons/export-dark.svg',
		},
		{
			name: 'history',
			path: 'assets/icons/history-dark.svg',
		},
		{
			name: 'lock-black',
			path: 'assets/icons/lock-black.svg',
		},
		{
			name: 'lock',
			path: 'assets/icons/lock-dark.svg',
		},
		{
			name: 'add',
			path: 'assets/icons/plus.svg',
		},
		{
			name: 'login',
			path: 'assets/icons/login-accent.svg',
		},
		{
			name: 'news',
			path: 'assets/icons/news.svg',
		},
		{
			name: 'reporting',
			path: 'assets/icons/reporting.svg',
		},
		{
			name: 'search',
			path: 'assets/icons/search-dark.svg',
		},
		{
			name: 'service',
			path: 'assets/icons/service-accent.svg',
		},
		{
			name: 'settings',
			path: 'assets/icons/settings.svg',
		},
		{
			name: 'tip',
			path: 'assets/icons/tip-dark-fill.svg',
		},
		{
			name: 'tracking',
			path: 'assets/icons/tracking.svg',
		},
		{
			name: 'trash',
			path: 'assets/icons/trash-danger.svg',
		},
		{
			name: 'dormancy-info',
			path: 'assets/icons/dormancy-info.svg',
		},
		{
			name: 'due-diligence',
			path: 'assets/icons/due-diligence.svg',
		},
		{
			name: 'reporting-mech',
			path: 'assets/icons/reporting-mech.svg',
		},
		{
			name: 'delivering-report',
			path: 'assets/icons/delivering-report.svg',
		},
		{
			name: 'laws-stack',
			path: 'assets/icons/laws-stack.svg',
		},
		{
			name: 'tier1',
			path: 'assets/icons/tier1.svg',
		},
		{
			name: 'mapping',
			path: 'assets/icons/mapping.svg',
		},
		{
			name: 'diligence-template',
			path: 'assets/icons/diligence-template.svg',
		},
		{
			name: 'trigger-interpretation',
			path: 'assets/icons/trigger-interpretation.svg',
		},
		{
			name: 'legislative',
			path: 'assets/icons/legislative.svg',
		},
		{
			name: 'tier2',
			path: 'assets/icons/tier2.svg',
		},
		{
			name: 'analysis',
			path: 'assets/icons/analysis.svg',
		},
		{
			name: 'topic-based-analysis',
			path: 'assets/icons/topic-based-analysis.svg',
		},
		{
			name: 'litigation-updates',
			path: 'assets/icons/litigation-updates.svg',
		},
		{
			name: 'support',
			path: 'assets/icons/support.svg',
		},
		{
			name: 'export-data',
			path: 'assets/icons/export-data.svg',
		},
		{
			name: 'archive',
			path: 'assets/icons/archive-dark.svg',
		},
		{
			name: 'unarchive',
			path: 'assets/icons/unarchive.svg',
		},
		{
			name: 'display',
			path: 'assets/icons/display-grey.svg',
		},
		{
			name: 'bullet',
			path: 'assets/icons/bullet.svg',
		},
	];

	/** Register icons. */
	public registerIcons(): void {
		this.icons.forEach(({ name, path }) => this.registerIcon(name, path));
	}

	private registerIcon(name: string, path: string): void {
		this.matIconRegistry.addSvgIcon(
			name,
			this.domSanitizer.bypassSecurityTrustResourceUrl(path),
		);
	}
}
