import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { IconsService } from '@scriptac/common/core/services/icons.service';

/** Base app component. */
@Component({
	selector: 'scriptaw-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	standalone: true,
	imports: [RouterOutlet],
})
export class AppComponent {

	public constructor() {
		const iconService = inject(IconsService);

		iconService.registerIcons();
	}
}
