import { Routes } from '@angular/router';

import { UserType } from '@scriptac/common/core/enums/user-type';
import { AccessTierLevel } from '@scriptac/common/core/enums/access-tier-level';

import { AuthGuard } from './modules/auth/guards/auth.guard';
import { NonAuthGuard } from './modules/auth/guards/non-auth.guard';
import { RestrictUserTypesGuard } from './modules/auth/guards/restrict-user-types.guard';
import { RestrictUserTierGuard } from './modules/auth/guards/restrict-user-tier.guard';
import { LayoutComponent } from './layouts/layout.component';

/** App routes. */
export const appRoutes: Routes = [
	{
		path: '',
		component: LayoutComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'search',
				loadChildren: () => import('./modules/search/search.routes').then(m => m.searchRoutes),
			},
			{
				path: 'tracking',
				loadChildren: () =>
					import('./modules/legislative-tracking/legislative-tracking.routes').then(m => m.legislativeTrackingRoutes),
				canActivate: [RestrictUserTierGuard],
				data: {
					breadcrumb: 'Legislative Tracking',
					userTiers: [AccessTierLevel.Tier2, AccessTierLevel.Tier3],
				},
			},
			{
				path: 'reporting',
				loadChildren: () =>
					import('./modules/reporting/reporting.routes').then(m => m.reportingRoutes),
				data: { breadcrumb: 'Reporting' },
			},
			{
				path: 'change-report',
				loadChildren: () =>
					import('./modules/change-report/change-report.routes').then(m => m.changeReportRoutes),
				data: { breadcrumb: 'Change Report' },
			},
			{
				path: 'profile',
				loadChildren: () => import('./modules/profile/profile.routes').then(m => m.profileRoutes),
				data: { breadcrumb: 'Profile' },
			},
			{
				path: 'dashboard',
				loadChildren: () =>
					import('./modules/admin-matrix/admin-matrix.routes').then(m => m.adminMatrixRoutes),
				canActivate: [RestrictUserTypesGuard],
				data: {
					userTypes: [UserType.Admin],
				},
			},
			{
				path: 'ira-distribution-rules',
				loadChildren: () =>
					import('./modules/ira-distribution-admin/ira-distribution-admin.routes').then(m => m.iraDistributionAdminRoutes),
				canActivate: [RestrictUserTypesGuard],
				data: {
					userTypes: [UserType.Admin],
					breadcrumb: 'IRA Distribution Rules',
				},
			},
			{
				path: 'priority-rules',
				loadChildren: () =>
					import('./modules/priority-rules-admin/priority-rules-admin.routes').then(m => m.priorityRulesAdminRoutes),
				canActivate: [RestrictUserTypesGuard],
				data: {
					userTypes: [UserType.Admin],
					breadcrumb: 'Priority Rules',
				},
			},
			{
				path: 'definitions',
				loadChildren: () =>
					import('./modules/definitions-admin/definitions-admin.routes').then(m => m.definitionsAdminRoutes),
				canActivate: [RestrictUserTypesGuard],
				data: {
					userTypes: [UserType.Admin],
					breadcrumb: { skip: true },
				},
			},
			{
				path: 'admin-news',
				loadChildren: () =>
					import('./modules/admin-news/admin-news.routes').then(m => m.adminNewsRoutes),
				canActivate: [RestrictUserTypesGuard],
				data: {
					userTypes: [UserType.Admin],
					breadcrumb: 'News',
				},
			},
			{
				path: 'settings',
				loadChildren: () =>
					import('./modules/settings/settings.routes').then(m => m.settingsRoutes),
				canActivate: [RestrictUserTypesGuard],
				data: {
					breadcrumb: 'Settings',
					userTypes: [UserType.Admin],
				},
			},
			{
				path: 'accounts',
				loadChildren: () =>
					import('./modules/accounts/accounts.routes').then(m => m.accountsRoutes),
				canActivate: [RestrictUserTypesGuard],
				data: {
					breadcrumb: 'Accounts',
					userTypes: [UserType.Admin],
				},
			},
			{
				path: 'admin-reporting',
				loadChildren: () =>
					import('./modules/admin-reporting/admin-reporting.routes').then(m => m.adminReportingRoutes),
				canActivate: [RestrictUserTypesGuard],
				canLoad: [RestrictUserTypesGuard],
				data: {
					breadcrumb: 'Reporting Mechanics',
					userTypes: [UserType.Admin],
				},
			},
			{
				path: 'not-found',
				loadComponent: () => import('./modules/shared/components/not-found-page/not-found-page.component')
					.then(m => m.NotFoundPageComponent),
			},
		],
	},
	{
		path: 'welcome',
		loadChildren: () =>
			import('./modules/welcome/welcome.routes').then(m => m.welcomeRoutes),
	},
	{
		path: 'news',
		loadChildren: () =>
			import('./modules/news/news.routes').then(m => m.newsRoutes),
	},
	{
		path: 'auth',
		loadComponent: () => import('./modules/auth/auth-page.component').then(m => m.AuthPageComponent),
		loadChildren: () => import('./modules/auth/auth.routes').then(m => m.authRoutes),
		canActivate: [NonAuthGuard],
	},
	{ path: '', pathMatch: 'full', redirectTo: 'welcome' },
	{ path: '**', redirectTo: 'auth' },
];
