<scriptaw-header [links]="links$ | async" />
<div class="container">
	<div class="breadcrumbs">
		<xng-breadcrumb separator=">">
			<ng-container *xngBreadcrumbItem="let breadcrumb; let last = last; let first = first; let count = count">
				@if (count > 1) {
					<div
						class="breadcrumb-text"
						[class.first-breadcrumb]="first"
						[class.last-breadcrumb]="last"
					>
						{{ breadcrumb }}
					</div>
				}
			</ng-container>
		</xng-breadcrumb>
	</div>
	<router-outlet />
	<scriptaw-footer />
</div>
