import { enableProdMode, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { provideNgxMask } from 'ngx-mask';
import { provideNativeDateAdapter } from '@angular/material/core';

import { httpInterceptorProviders } from '@scriptac/common/core/interceptors';

import { appRoutes } from './app/app.routes';
import { AppComponent } from './app/app.component';

/** Checks whether the app works in prod environment. */
if (import.meta.env.NG_APP_PRODUCTION === 'TRUE') {
	enableProdMode();
}

bootstrapApplication(AppComponent, {
	providers: [
		importProvidersFrom(
			BrowserModule,
			CalendarModule.forRoot({
				provide: DateAdapter,
				useFactory: adapterFactory,
			}),
		),
		provideNgxMask(),
		httpInterceptorProviders,
		provideHttpClient(withInterceptorsFromDi()),
		provideAnimations(),
		provideRouter(appRoutes),
		provideNativeDateAdapter(),
	],
})
	.catch(err => console.error(err));
